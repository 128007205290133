<template>
  <div class="contact">
    <img-div
      class-name=""
      height="320"
      :img-url="require('@/assets/contact/mask.png')"
    />
    <img
      class="locationImg"
      src="../../assets/contact/locationimg.png"
      alt=""
    />
    <div class="container flex flex-jsb mt340">
      <div class="contact-list">
        <div class="flex flex-vc">
          <img src="../../assets/contact/email.png" alt="" />
          <span v-html="$t('contact.email')"></span>
        </div>
      </div>
      <div class="location">
        <div class="flex flex-vc">
          <img src="../../assets/contact/location.png" alt="" />
          <span>北京市朝阳区霄云路40号院1号楼国航世纪大厦10层</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
export default {
  components: {
    ImgDiv,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.contact {
  background-color: var(--whiteColor);
  position: relative;
  padding-bottom: 40px;
  min-height: calc(100vh - 60px - 207px);
  .mt340 {
    margin-top: 280px;
  }
  .contact-list,
  .location,
  .service {
    font-size: 14px;
    color: var(--blackColor);
    img,
    .img {
      width: 40px;
      margin-right: 16px;
    }
    img {
      height: 40px;
    }
    a {
      color: var(--blue);
    }
  }
  .service {
    cursor: pointer;
  }
  .mt10 {
    margin-top: 10px;
  }
  .locationImg {
    // margin: auto;
    width: 1180px;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
