<template>
  <div class="case">
    <img-div
      class-name=""
      height="320"
      :img-url="require('@/assets/case/banner@2x.png')"
    >
      <div class="p1">精品案例</div>
    </img-div>
    <div class="case-list">
      <div
        class="list-item flex flex-vc flex-jsb"
        v-for="(item, index) in itemList"
        :key="index"
      >
        <span>{{ item.name }}</span>
        <span class="getPay" @click="showPay">支付获取</span>
      </div>
    </div>
    <model-box v-model="show" />
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
import ModelBox from "@/components/business/ModelBox.vue";
export default {
  components: {
    ImgDiv,
    ModelBox,
  },
  data() {
    return {
      itemList: [
        {
          name: "汽车文化类网站搭建案例",
        },
        {
          name: "婚庆策划类网站搭建案例",
        },
        {
          name: "建筑装修类网站搭建案例",
        },
        {
          name: "生活服务类网站搭建案例",
        },
        {
          name: "电子商务类网站搭建案例",
        },
      ],
      show: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    showPay() {
      this.show = !this.show;
    },
  },
};
</script>
<style lang="scss" scoped>
.case {
  background-color: var(--whiteColor);
  position: relative;
  padding-bottom: 40px;
  min-height: calc(100vh - 60px - 207px);
  .p1 {
    margin-top: 132px;
  }
  .case-list {
    width: 1140px;
    margin: -80px auto 0;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    padding: 32px;
    position: relative;
    color: var(--blackColor);
    font-size: 16px;
    .list-item {
      height: 60px;
      border-bottom: 1px var(--borderColor) solid;
      .getPay {
        color: var(--blue);
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>
