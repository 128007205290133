<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="270px"
    :show-close="false"
    append-to-body
    lock-scroll
  >
    <div class="tipBox">
      <img
        class="close-icon"
        src="@/assets/case/delete.png"
        alt=""
        @click="dialogVisible = false"
      />
      <div class="s-title">微信支付</div>
      <div class="title"><span class="money">¥</span><span>288.00</span></div>
      <div class="content text-center">
        <img class="empty-img" src="@/assets/case/ercode.png" alt="" />
      </div>
    </div>
    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.value,
    };
  },
  computed: {
    personOpenHelpSrc() {
      const lang = this.$i18n.locale;
      let obj;
      if (lang === "zh") obj = { name: "个人如何开户", cate_name: "开户相关" };
      else obj = { name: "個人如何開戶", cate_name: "開戶相關" };
      const itemObj = {
        article_id: 2,
        ...obj,
      };
      const path = {
        path: "/helpDetail",
        query: {
          info: JSON.stringify(itemObj),
        },
      };
      return path;
    },
    mechanismOpenHelpSrc() {
      const lang = this.$i18n.locale;
      let obj;
      if (lang === "zh")
        obj = { name: "机构如何开户？", cate_name: "开户相关" };
      else obj = { name: "機構如何開戶？", cate_name: "開戶相關" };
      const itemObj = {
        article_id: 1,
        ...obj,
      };
      const path = {
        path: "/helpDetail",
        query: {
          info: JSON.stringify(itemObj),
        },
      };
      return path;
    },
  },
  watch: {
    value(n, o) {
      if (n === o) return;
      this.dialogVisible = n;
    },
    dialogVisible(n, o) {
      if (n === o) return;
      this.$emit("input", n);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog {
  position: relative;
  .el-dialog__header {
    height: 0;
    padding: 0;
  }
  .el-dialog__body {
    padding: 24px 40px 40px;
  }
  .el-dialog__footer {
    padding: 0;
    height: 0;
  }
}
.tipBox {
  color: var(--erCodeTitleColor);
  text-align: center;
  font-weight: 600;
  .s-title {
    font-size: 14px;
  }
  .title {
    font-size: 30px;
    margin-top: 8px;
    line-height: 20px;
    color: #ff3b30;
    .money {
      font-size: 14px;
    }
  }
  .empty-img {
    width: 190px;
    margin: 20px 0 0;
  }
  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 18px;
    cursor: pointer;
  }
}
</style>
